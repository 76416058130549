import { collection, onSnapshot } from "firebase/firestore";
import { atom } from "recoil";
import { database } from "../firebase";
import { Tag } from "../typeDefs/typeDefs";

export const tagState = atom<Array<Tag>>({
  key: "tagStateKey",
  default: [],
  effects: [
    ({ setSelf }) => {
      const unsub = onSnapshot(collection(database, "tags"), (snap) => {
        let tags = [] as Array<Tag>;
        snap.docs.forEach((doc: any) => {
          tags.push(doc.data());
        });
        setSelf(tags);
      });

      return unsub;
    },
  ],
});

export const tagListState = atom<Array<string>>({
  key: "tagListStateKey",
  default: [],
});
