import { message } from "antd";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

export const AuthContext = createContext<any>(null);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: any }) {
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [isLoading, setIsloading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsloading(false);
    });
    return () => unsubscribe();
  }, []);

  const signUp = (email: string, password: string) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        signInWithEmailAndPassword(auth, email, password)
          .then(() => {
            message.info("Logging in... Please wait.");
          })
          .catch((err: any) => {
            console.error(err);
            message.error("Failed to login... Do you have an account?");
          });
      })
      .catch((err: any) => console.error(err));
  };

  const loginFunc = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password)
      .then((e) => {
        message.success("You're in");

        return true;
      })
      .catch((err: any) => {
        console.error(err);
        message.error("Failed to login... Do you have an account?");
      });
  };

  const logoutFunc = () => {
    signOut(auth)
      .then(() => {
        //setCurrentUser(null);
        message.info("Logged out");
      })
      .catch((err) => console.error(err));
  };

  const sendPasswordResetFunc = (email: string) => {
    sendPasswordResetEmail(auth, email);
  };

  const resetPasswordFunc = (code: any, newPassword: string) => {
    confirmPasswordReset(auth, code, newPassword);
  };

  const values = {
    currentUser,
    isLoading,
    signUp,
    loginFunc,
    logoutFunc,
    sendPasswordResetFunc,
    resetPasswordFunc,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}
