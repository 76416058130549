import { Carousel, Form } from "antd";
import { useRecoilValue } from "recoil";
import { WordCloudParent } from "../WordCloud/WordCloud";
import { WordFrequenciesTwo } from "../WordFrequencies/WordFrequenciesTwo";
import { WordSearch } from "../WordSearch/WordSearch";
import { windowState } from "../recoil/pageSizeState";
import { tagState } from "../recoil/tagState";
import { Article } from "../typeDefs/typeDefs";
import { ArticleSentiment } from "../ArticleSentiment/ArticleSentiment";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";

const articles: Array<Article> = [
  { pubDate: new Date().getTime(), tags: ["hello", "world"] },
];

const tagList = [{}];

export const Landing = () => {
  const [tagForm] = Form.useForm();
  const tagOptions = useRecoilValue(tagState);
  const { width } = useRecoilValue(windowState);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,.7)",
      }}
    >
      <div
        className="hsb"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignSelf: "center",
          width: width < 1000 ? "100%" : "60%",
          height: "100%",
        }}
      >
        <div
          style={{
            alignContent: "center",
            width: "100%",
            marginTop: "3rem",
            paddingBottom: "10rem",
            overflowY: "scroll",
          }}
        >
     
        </div>
      </div>
    </div>
  );
};
