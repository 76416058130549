import { atom } from "recoil";

export const windowState = atom<{ width: number; height: number }>({
  key: "windowStateKey",
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  effects_UNSTABLE: [
    ({ setSelf }) => {
      const listener = () => {
        setSelf({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    },
  ],
});
