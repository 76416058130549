import { Button, Form, Input } from "antd";
import { useAuth } from "../context/context";

export const Login = ({ closeModal }: any) => {
  const { loginFunc } = useAuth();
  const [loginForm] = Form.useForm();

  const handleLoginForm = () => {
    loginForm
      .validateFields()
      .then((values) => {
        if (true) {
          loginFunc(values.email, values.password);
          closeModal(false);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        paddingTop: "3rem",
        paddingBottom: "3rem",
      }}
    >
      <Form form={loginForm} style={{ maxWidth: "350px" }}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Your email address is required.",
            },
            {
              type: "email",
              message: "You must enter a valid email address.",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "You must enter your password.",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
      <Button onClick={() => handleLoginForm()} style={{ width: "30%" }}>
        Login
      </Button>
    </div>
  );
};
