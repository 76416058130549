import { useEffect, useRef } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import { ClientRequest } from "http";
import { useRecoilValue } from "recoil";
import { geoLocationState } from "../recoil/geoLocationState";
//const mapboxToken = process.env.MAPBOX;
const mapStyle = require("./style.json");
mapboxgl.accessToken =
  "pk.eyJ1IjoicGhpbGlwc2hhdyIsImEiOiJja3A2dWFiZG0wdDh6MnFrdmFvY3V0OXVyIn0.7fRKRYwIEe89NDmKfZhWng";

export const Mapbox = () => {
  const mapContainer = useRef(null);
  const geoLocation = useRecoilValue(geoLocationState);
  const map = useRef<Map>();

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current ? mapContainer.current : "mapContainer", //"mapContainer", // container ID
      style: mapStyle,
      center: [geoLocation.lat, geoLocation.long], // starting position [lng, lat]
      zoom: geoLocation.zoom, // starting zoom
    });
  }, [geoLocation]);

  useEffect(() => {
    let newLat = geoLocation.lat + .001
    let newLong = geoLocation.long + .001
    let count = 0

    setInterval(() => {  
      if(!map.current) return
      if(count < 500){
        newLat = newLat + .0003
        newLong = newLong + .0003
        count ++
      }

      if(count >= 500 && count < 1000){
        newLat = newLat - .0003
        newLong = newLong - .0003
        count ++
      }

      if(count >= 1000){
        count = 0
      }

      
      map.current.easeTo({center: [newLat,newLong]})},100)

    // let newLat = geoLocation.lat + .1
    // let newLong = geoLocation.long + .1
    // map.current.easeTo({center: {lat: newLat, lng: newLong}})
  },[geoLocation])

  return (
    <div
      id="mapContainer"
      ref={mapContainer}
      style={{ width: "100%", height: "100vh" }}
    ></div>
  );
};
