import { initializeApp } from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCr2NBeLU3ZkNtMBWWSNQj98BuLr_6sEL8",
  authDomain: "populistpapers-6245d.firebaseapp.com",
  projectId: "populistpapers-6245d",
  storageBucket: "populistpapers-6245d.appspot.com",
  messagingSenderId: "852624814591",
  appId: "1:852624814591:web:7da0480c8ac9bf17abf7ca",
  measurementId: "G-LGYVT078T8",
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getFirestore(app);
export const storage = getStorage(app);
