import { atom } from "recoil";

export const geoLocationState = atom<{
  lat: number;
  long: number;
  zoom: number;
}>({
  key: "geoLocationStateKey",
  default: { lat: 174.774899, long: -41.278116, zoom: 10 },
});
