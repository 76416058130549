import { ConfigProvider } from "antd";
import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { About } from "./About/About";
import "./App.css";
import { Landing } from "./Landing/Landing";
import { Mapbox } from "./Mapbox/Mapbox";
import { PageMenuBar } from "./PageMenuBar/PageMenuBar";
import { PrivateRoute } from "./PrivateRoute/PrivateRoute";
import { Reports } from "./Reports/Reports";
import { Timelines } from "./Timelines/Timelines";
import { AuthProvider } from "./context/context";
import { Support } from "./Support/Support";
import bgImage from "./Landing/Image3.png"

const ComponentOne = lazy(() => import("./TimelineEditor/TimelineEditor"));
const ComponentTwo = lazy(() => import("./TextReview/TextReview"));

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#46A6A6",
          colorTextSecondary: "#BFECEC",
          colorText: "black",
        },
      }}
    >
      <AuthProvider>
        <RecoilRoot>
          <BrowserRouter>
            <div style={{ height: "100vh", width: "100vw" }}>
            <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "clip",
                  backgroundImage: `url(${bgImage})`,
                  opacity: ".6"
                }}
              ></div>
              <div
                style={{
                  zIndex: 0,
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "clip",
                }}
              >
                <Mapbox />
              </div>
              <div
                style={{
                  zIndex: 5,
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100vw",
                  height: "100vh",
                  overflowY: "hidden",
                }}
              >
                <PageMenuBar />
                <Routes>
                  <Route path="/" Component={Landing} />
                  {/* <Route path="/conflicts" Component={Timelines} />
                  <Route path="/reports" Component={Reports} />
                  <Route path="/support" Component={Support} />
                  <Route path="/about" Component={About} /> */}
                  <Route
                    path="/editor"
                    element={<PrivateRoute children={<ComponentOne />} />}
                  />
                  {/* <Route path="/text-review" Component={ComponentTwo} /> */}
                </Routes>
              </div>
            </div>
          </BrowserRouter>
        </RecoilRoot>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
